import contact from "./contact";
import produits from "./produits";
import materiels from "./materiels";
import items from "./items";
import breadcrumb from "./breadcrumb";

export default {
  namespaced: true,
  actions: {
    init: async ({ dispatch }) => {
      dispatch("contact/init");
      dispatch("produits/init");
      dispatch("materiels/init");
      dispatch("items/init");
      dispatch("breadcrumb/init");
    },
  },
  modules: {
    contact,
    produits,
    materiels,
    items,
    breadcrumb,
  },
};
