<template>
  <nav
    class="flex items-center justify-between flex-wrap bg-gradient-to-br from-gradient-start to-gradient-end"
  >
    <div class="flex items-center flex-shrink-0 text-white mr-6">
      <router-link to="/" class="flex items-center mt-[-10px]">
        <img :src="logo" alt="Logo" class="h-12 w-auto" />
      </router-link>
    </div>
    <div class="block lg:hidden">
      <button
        @click="open = !open"
        class="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white"
      >
        <svg
          class="fill-current h-3 w-3"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v15z" />
        </svg>
      </button>
    </div>
    <div
      :class="{ block: open, hidden: !open }"
      class="w-full block flex-grow items-start justify-start lg:justify-start lg:flex lg:items-center lg:w-auto"
    >
      <div class="text-sm lg:flex-grow">
        <router-link
          to="/qui-sommes-nous"
          class="block mt-6 lg:inline-block lg:mt-0 text-xl text-white text-left hover:text-gray-300 mr-20"
        >
          Qui sommes nous
        </router-link>
        <router-link
          to="/nos-produits"
          class="block mt-6 lg:inline-block lg:mt-0 text-xl text-white text-left hover:text-gray-300 mr-20"
        >
          Nos produits
        </router-link>

        <router-link
          to="/contact"
          class="block mt-6 lg:inline-block lg:mt-0 text-xl text-white text-left hover:text-gray-300 mr-20"
        >
          Contact
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
import logo from "@/assets/images/logo.jpg";

export default {
  data() {
    return {
      open: false,
      logo,
    };
  },
};
</script>
<style>
nav a:hover {
  transition: all 0.5s;
  transform: scale(1.2);
}
</style>
