export default {
  namespaced: true,
  state: {
    breadcrumb: {
      parentCategory: null,
      childCategory: null,
      item: null,
    },
  },
  getters: {},
  mutations: {
    setBreadcrumb(state, { parentCategory, childCategory, item }) {
      state.breadcrumb = { parentCategory, childCategory, item };
    },
  },
  actions: {
    init: async () => {
      // Init vars in form
    },
  },
};
