import axios from "axios";

const instance = axios.create({
  // baseURL: process.env.baseURL,

  // baseURL: "http://localhost:3001/api/",
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    // "x-kt-api-key": process.env.x_kt_api_key,
    // // "x-kt-api-key":"3503faad-f132-415c-b64d-d56a09e2b17b",
  },
  validateStatus: function (status) {
    return status >= 200 && status < 509;
  },
});
export default {
  namespaced: true,
  state: {
    // *******************    variables   start *********************
    list_categories: [],
  },
  getters: {
    get_axios() {
     
      return instance;
    },
  },
  mutations: {
    set_list_categories: function (state, list_categories) {
      state.list_categories = list_categories.categories;
    },
  },
  actions: {
    init: async () => {
      // Init vars in form
    },
    get_all_categories: async ({ commit, getters }) => {
      try {
        const response = await getters.get_axios.get("/categorie");

        commit("set_list_categories", response.data);
      } catch (e) {
        console.log(e);

        return {
          success: false,
          message: e.message,
        };
      }
    },
  },
};
