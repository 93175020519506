import axios from "axios";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,

  headers: {
    // "x-kt-api-key": process.env.x_kt_api_key,
    // // "x-kt-api-key":"3503faad-f132-415c-b64d-d56a09e2b17b",
  },
  validateStatus: function (status) {
    return status >= 200 && status < 509;
  },
});

export default {
  namespaced: true,
  state: {
    // *******************    variables   start *********************
  },
  getters: {
    get_axios(state) {
      // if (state.token != "") {
      //   instance.defaults.headers.common[
      //     "Authorization"
      //   ] = `Bearer ${state.token}`;
      // }
      return instance;
    },
  },
  mutations: {},
  actions: {
    init: async () => {
      // Init vars in form
    },

    sendEmail: async ({ getters }, params) => {
      //   const axios = rootGetters["requests/auth/get_axios"];
      try {
        const response = await getters.get_axios.post(
          `/contact/send_email`,
          params
        );

        // const response = await axios.get("/country");
        return response.data;
      } catch (e) {
        return {
          success: false,
          message: e.message,
        };
      }
    },
  },
};
