import axios from "axios";

const instance = axios.create({
  // baseURL: process.env.baseURL,

  // baseURL: "http://localhost:3001/api/",
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    // "x-kt-api-key": process.env.x_kt_api_key,
    // // "x-kt-api-key":"3503faad-f132-415c-b64d-d56a09e2b17b",
  },
  validateStatus: function (status) {
    return status >= 200 && status < 509;
  },
});
export default {
  namespaced: true,
  state: {
    // *******************    variables   start *********************
    category: {},
  },
  getters: {
    get_axios() {
      return instance;
    },
  },
  mutations: {
    set_categorie: function (state, category) {
      state.category = category.categorie;
    },
  },
  actions: {
    init: async () => {
      // Init vars in form
    },
    async getCategoryWithSubCategoriesAndItems_slug({ getters, commit }, slug) {
      try {
        const response = await getters.get_axios.get(`/categories/${slug}`);
        commit("set_categorie", response.data);
      } catch (error) {
        console.error(
          `Une erreur est survenue lors de la récupération de la catégorie : ${error}`
        );
      }
    },
  },
};
