import { createApp } from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./assets/css/tailwind.css";
import Notifications from "@kyvg/vue3-notification";
import velocity from "velocity-animate";
// import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Vue3TouchEvents from "vue3-touch-events";
import "@fortawesome/fontawesome-free/css/all.css";
import VueGtag from "vue-gtag";
import { createHead } from "@vueuse/head";

// Ajout des icônes à "library" a déjà été fait avec library.add(fas)
const app = createApp(App);
const head = createHead();

app.component("font-awesome-icon", FontAwesomeIcon); // Enregistrement du composant FontAwesome
app.use(store);
app.use(router);
app.use(Notifications, { velocity });
app.use(head);
app.use(Vue3TouchEvents);

app.use(
  VueGtag,
  {
    config: { id: "G-PFCRNG45M8" },
    appName: "ReyssacBois",
    pageTrackerScreenviewEnabled: true,
  },
  router
);

app.mount("#app");
