import axios from "axios";

const instance = axios.create({
  // baseURL: process.env.baseURL,

  // baseURL: "http://localhost:3001/api/",
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    // "x-kt-api-key": process.env.x_kt_api_key,
    // // "x-kt-api-key":"3503faad-f132-415c-b64d-d56a09e2b17b",
  },
  validateStatus: function (status) {
    return status >= 200 && status < 509;
  },
});
export default {
  namespaced: true,
  state: {
    // *******************    variables   start *********************
    items: [],
    sous_category: {},
  },
  getters: {
    get_axios() {
      return instance;
    },
  },
  mutations: {
    set_items: function (state, items) {
      state.items = items.items;
    },

    set_sous_category: function (state, sous_category) {
      state.sous_category = sous_category.categorie;
    },
  },
  actions: {
    init: async () => {
      // Init vars in form
    },
    async get_all_items_to_category_by_slug({ getters, commit }, slug) {
      try {
        const response = await getters.get_axios.get(`/items_category/${slug}`);
        commit("set_items", response.data);
      } catch (error) {
        console.error(
          `Une erreur est survenue lors de la récupération de la catégorie : ${error}`
        );
      }
    },
    async get_item_by_slug({ getters, commit }, slug) {
      try {
        const response = await getters.get_axios.get(`/item/${slug}`);
        return response.data.item;
      } catch (error) {
        console.error(
          `Une erreur est survenue lors de la récupération de la l'item : ${error}`
        );
      }
    },
    async get_sous_category_slug({ getters, commit }, slug) {
      try {
        const response = await getters.get_axios.get(`/categorie_slug/${slug}`);
        return response.data.categorie;
        // commit("set_sous_category", response.data);
      } catch (error) {
        console.error(
          `Une erreur est survenue lors de la récupération de la l'item : ${error}`
        );
        return null;
      }
    },
  },
};
