<template>
  <div id="app">
    <NavBar />
    <router-view />
    <notifications position="top right" width="500" classes="notify_custom" />
  </div>
</template>
<script>
import NavBar from "./components/NavBar";
// import Notifications from "@kyvg/vue3-notification";
export default {
  name: "App",
  components: {
    NavBar,
    // Notifications,
  },
  mounted() {
    // Une fois que tout le contenu asynchrone est chargé :
    document.dispatchEvent(new Event("render-event"));
  },
  metaInfo: {
    title: "Nos produits",
    meta: [
      {
        name: "description",
        content:
          "Explorez notre large gamme de produits. Nous avons des produits pour tous vos besoins.",
      },
      {
        property: "og:title",
        content: "Nos produits",
      },
      {
        property: "og:description",
        content:
          "Explorez notre large gamme de produits. Nous avons des produits pour tous vos besoins.",
      },
    ],
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
