import { createRouter, createWebHistory } from "vue-router";
const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/qui-sommes-nous",
    name: "QuiSommesNous",
    component: () => import("../views/QuiSommesNous.vue"),
  },
  {
    path: "/nos-produits",
    name: "NosProduits",
    component: () => import("../views/NosProduits.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/materiel/:slug",
    name: "Materiel",
    component: () => import("../views/Materiels.vue"),
    props: true, // Cela permet de passer les paramètres de route comme props au composant
  },
  {
    path: "/categorie/:slug",
    name: "Items",
    component: () => import("../views/Items.vue"),
    props: true, // Cela permet de passer les paramètres de route comme props au composant
  },
  {
    path: "/item/:slug",
    name: "item-detail",
    component: () => import("../views/Item.vue"),
    props: true, // Cela permet de passer les paramètres de route comme props au composant
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
