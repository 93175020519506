import { createStore } from "vuex";

import requests from "./requests";
export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {
    init: async ({ dispatch }) => {
      dispatch("requests/init");
    },
  },
  modules: {
    requests,
  },
});
